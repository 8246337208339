export default {
	API_DEFAULT_ACTION: '/api/Form',

	STORAGE_AUTH_TOKEN: "auth",
	STORAGE_AGENT_ID: "agentId",

	ERROR_CODE_FORBIDDEN: "403",
	ERROR_CODE_NOT_FOUND: "404",
	ERROR_CODE_EXPIRED: "410",

	DEBOUNCE_INPUT_TIMEOUT: 800,

	// todo sync with db
	COLOR_STATUS_DRAFT: "#555",
	COLOR_STATUS_SUBMITTED: "#ff9800",
	COLOR_STATUS_CONFIRMED: "#4caf50",

	//in _variables.scss, config.js and plugins/vuetify.js
	WIDTH_SM: 600,
	WIDTH_MD: 960,
	WIDTH_LG: 1366 - 16,
	WIDTH_XL: 1904,
}
