import config from '@/config'

let state = {
    // данные из БЕ - переустанавливаются при каждом fetchPage
    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    currentRouteName: null,

    // реактивные размеры экрана
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,

    withDrawer: false,

    hasChanges: false,
    dialogChanges: {
        visible: false,
        callback: null,
        loading: null,
        params: null
    },
    dialogDelete: {
        visible: false,
        callback: null,
        loading: null,
        params: null
    },
    dialogError: {
        visible: false,
        callback: null,
        message: null,
        loading: null,
        params: null
    },
};

export default state;
