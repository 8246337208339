export default [
	{
		name: 'auth',
		path: '/auth',
		component: () => import('../components/auth'),
	},
	{
		path: '/', component: () => import('../components/layouts/layout-main'), meta: {},
		children: [
			{
				path: '/',
				name: 'orders',
				component: ()=>import('../components/orders'),
				meta: {api: "OrderListController"}
			},
			{
				path: '/order/:id',
				props: true,
				name: 'order',
				component: ()=>import('../components/order'),
				meta: {api: "OrderItemController"}
			},
			{
				path: '/order/new',
				name: 'order-new',
				component: ()=>import('../components/order/order-new'),
				//meta: {api: "OrderItemController"}
			},
			{
				path: '/order/:orderId/product/new',
				props: true,
				name: 'product-new',
				component: ()=>import('../components/product'),
				meta: {api: "ProductItemController"}
			},
			{
				path: '/order/:orderId/product/:id',
				props: true,
				name: 'product',
				component: ()=>import('../components/product'),
				meta: {api: "ProductItemController"}
			},
			/*{
				path: '/settings',
				name: 'settings',
				component: ()=>import('../components/settings'),
				// meta: {api: "OrderController"}
			},*/
			{
				path: '/settings/google/auth',
				name: 'google-auth',
				component: ()=>import('../components/google-auth'),
				// meta: {api: "OrderController"}
			},
			{
				path: '/settings/google/auth/confirmation',
				name: 'google-auth-confirmation',
				component: ()=>import('../components/google-auth-confirmation'),
				//meta: {api: "GoogleAuthCodeController", query: {code: "code"}}
			},
			{
				path: '/logout',
				name: 'logout',
				component: ()=>import('../components/logout'),
				meta: {api: "RestrictedController"}
			},
			{
				path: '/error/:code',
				name: 'error',
				component: ()=>import('../components/error'),
				props: true,
			},
		]
	},
];
